.top-nav {
  height: 72px;
  background: #fff;
  width: 100%;
}
.top-box-container {
  height: 72px;
  width: 1400px;
  margin: 0 auto;
  .logo-box {
    height: 48px;
    width: 169px;
    .logo-icon {
      height: 48px;
      width: 169px;
    }
  }
}
.top-menu {
  & > .ant-col {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 14px;
    position: relative;
    &.current {
      a {
        font-weight: 600;
        color: #3061f5;
        font-size: 16px;
      }
      &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 3px;
        background: #3061f5;
        border-radius: 2px;
        opacity: 0.95;
        bottom: -10px;
        left: 50%;
        margin-left: -8px;
      }
    }
    a,
    span {
      padding: 0 16px;
      color: #666666;
      cursor: pointer;
    }
  }
}
