.footer-box {
  height: 154px;
  background: #252525;
  padding-top: 40px;
  .bottom-menu {
    & > .ant-col {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 14px;
      padding: 0 8px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 14px;
        background: #454545;
        right: 0;
        top: 50%;
        margin-top: -7px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      a {
        color: #999999;
      }
    }
  }
  .footer-info {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
    margin-top: 8px;
    a {
      color: #999;
    }
    & > .ant-col {
      margin: 0 8px;
    }
    img {
      width: 19px;
      height: 21px;
      margin-right: 13px;
      vertical-align: middle;
    }
  }
  .footer-contact {
    width: 1400px;
    margin: 0 auto 0;
    height: 108px;
    .footer-logo {
      height: 40px;
      margin-bottom: 15px;
      width: 131px;
    }
    .footer-tip {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
    }
    & > .ant-col {
      &:first-child {
        width: 543px;
        border-right: 1px solid #454545;
        margin-right: 96px;
      }
    }
    .contact-info {
      & > .ant-col {
        &:first-child {
          width: 414px;
        }
      }
    }
    .footer-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: normal;
      color: #f5f5f5;
      line-height: 14px;
      margin-top: 23px;
    }
    .footer-qq {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
      margin-top: 26px;
    }
    .footer-tel {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 32px;
      margin-top: 15px;
    }
  }
}
