.top-login {
  height: 48px;
  border-bottom: 1px solid #d2d2d2;
  // padding: 0 32px;
  & > .ant-row {
    height: 48px;
    width: 1400px;
    margin: 0 auto;
    .left-part {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      a {
        color: #3061f5;
        padding-right: 16px;
      }
      a.black {
        color: #333333;
      }
      &.logined {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        span,
        img {
          vertical-align: middle;
        }
        .vip-icon {
          max-width: 20px;
          max-height: 20px;
          margin: 0 5px;
        }
        & > .ant-col {
          position: relative;
          margin-right: 8px;
          padding-right: 8px;
          &.red {
            cursor: pointer;
            color: #fa5a64;
            .iconfont {
              font-size: 16px;
              margin-left: 5px;
            }
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
          &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 14px;
            background: #c1c1c1;
            top: 50%;
            margin-top: -7px;
            right: 0;
          }
        }
      }
    }
    .right-part {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 12px;
      .phone-icon {
        font-size: 16px;
        color: #999;
        margin-right: 2px;
        vertical-align: middle;
        & + span {
          vertical-align: middle;
        }
      }
      .ant-popover-open {
        color: #3061f5;
        .phone-icon {
          color: #3061f5;
        }
      }
      & > .ant-col {
        padding: 0 16px;
        position: relative;
        cursor: pointer;
        a {
          color: #666666;
        }
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 14px;
          background: #d2d2d2;
          right: 0;
          top: 50%;
          margin-top: -7px;
        }
        &:last-child {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.top-download {
  .code-box {
    width: 104px;
    height: 104px;
    background: #ffffff;
    border-radius: 7px;
    border: 1px solid #dadada;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 7px;
    }
  }
  .font1 {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }
  .font2 {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3061f5;
    line-height: 14px;
    margin-top: 16px;
  }
}
